"use client";

import "@rainbow-me/rainbowkit/styles.css";

import { BaseProviders, OnramperProvider } from "@sushiswap/ui";
import { WagmiStoreVersionCheck } from "src/lib/wagmi/components/wagmi-store-version-check";
import { QueryClientProvider } from "./query-client-provider";
import { WagmiConfig } from "./wagmi-provider";
import { UserProvider } from "./user-provider";
import { CheckerProvider } from "src/lib/wagmi/systems/Checker/Provider";

export function RootProviders({ children }: { children: React.ReactNode }) {
  return (
    <BaseProviders forcedTheme="dark">
      <OnramperProvider>
        <QueryClientProvider>
          <WagmiConfig>
            <WagmiStoreVersionCheck>
              <UserProvider>
                <CheckerProvider>{children}</CheckerProvider>
              </UserProvider>
            </WagmiStoreVersionCheck>
          </WagmiConfig>
        </QueryClientProvider>
      </OnramperProvider>
    </BaseProviders>
  );
}

import { cn } from "~web/lib/utils";

interface BlackholeIconProps {
  width?: number | string;
  className?: string;
}

export default function BlackholeIcon({
  width = 248,
  className,
}: BlackholeIconProps) {
  return (
    <svg
      width={width}
      height="auto"
      viewBox="0 0 248 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("max-w-full", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.531 4.56096C179.883 4.84867 176.598 6.20406 175.814 10.3117C175.564 11.6214 174.869 12.5869 172.268 13.0411C172.422 13.0142 172.572 12.9883 172.718 12.963C175.671 12.4531 177.187 12.1912 178.27 9.59843C179.158 7.47308 180.95 6.86836 181.793 6.70837L181.803 6.77176C182.618 6.62944 184.552 6.54857 186.054 8.30463C187.844 10.3959 189.359 10.1284 192.31 9.60746C192.456 9.5816 192.606 9.55511 192.76 9.52826C190.159 9.98247 189.216 9.30309 188.594 8.14559C186.667 4.55898 183.212 4.35211 181.541 4.62513L181.531 4.56096Z"
        fill="#FAFF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.407 14.3258L176.27 14.3898C176.877 15.7917 177.925 16.94 179.251 17.6564C180.578 18.3729 182.109 18.6175 183.607 18.3523L183.301 16.297C182.248 16.4834 181.172 16.3115 180.24 15.808C179.46 15.3867 178.817 14.7529 178.378 13.98L176.407 14.3258Z"
        fill="#FAFF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M189.386 12.1309C189.205 13.6462 188.548 15.0725 187.512 16.1939C186.461 17.3311 185.079 18.0913 183.581 18.3565L183.275 16.3012C184.328 16.1148 185.299 15.5806 186.038 14.7813C186.633 14.1379 187.05 13.3517 187.252 12.5052L189.386 12.1309Z"
        fill="#FAFF00"
      />
      <rect
        width="49.0514"
        height="0.44293"
        transform="matrix(0.98611 -0.166113 0.154392 0.988013 158.32 15.4102)"
        fill="#FAFF00"
      />
      <path
        d="M236.371 6.69336H248V8.68871H236.39V10.7604H246.035L248 12.7965H236.42V12.8118H236.39V14.8376H246.035L248 16.8737H236.405L234.446 14.8427V12.7965H236.39V12.771L234.446 10.7553V8.68871L236.371 6.69336Z"
        fill="#FAFF00"
      />
      <path
        d="M11.629 6.69336H-0.000187874V8.68871H11.6094V10.7604H1.96421L-0.000187874 12.7965H11.5799V12.8118H11.6094V14.8376H1.96421L-0.000187874 16.8737H11.5947L13.5541 14.8427V12.7965H11.6094V12.771L13.5541 10.7553V8.68871L11.629 6.69336Z"
        fill="#FAFF00"
      />
      <path
        d="M29.3064 8.72943L31.2708 6.69336V14.8376H42.8607L40.8963 16.8737H29.3064V8.72943Z"
        fill="#FAFF00"
      />
      <path
        d="M205.139 7.5199L207.103 7.19336V14.8382H218.693L216.729 16.8743H205.139V7.5199Z"
        fill="#FAFF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.2363 16.8737H72.1605V8.69789L70.2363 6.69336H61.7347L58.6129 9.9291V16.8737H60.5763L64.5008 12.8016H70.2363V16.8737ZM60.5862 8.73372H70.2388V10.7657H63.6973L60.5862 13.9943V8.73372Z"
        fill="#FAFF00"
      />
      <path
        d="M99.5027 8.72943L101.467 6.69336H89.8771L87.9127 8.72943V14.8376L89.8771 16.8737H99.5027L101.467 14.8376H89.8771V8.72943H99.5027Z"
        fill="#FAFF00"
      />
      <path
        d="M117.219 8.72943L119.184 6.69336V12.7914H119.193L121.148 10.7655H124.085L128.014 6.69336H130.774L125.863 11.7835L130.774 16.8737H128.014L124.085 12.8016H119.184V14.8376L117.219 16.8737V8.72943Z"
        fill="#FAFF00"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.116 15.8941L160.08 15.5676L160.08 6.69336L158.116 8.72943L158.116 15.8941ZM146.526 16.8737H148.49V8.72944L146.526 6.69337V16.8737ZM148.49 10.7655L150.455 10.7655L158.116 10.7655V12.8016H148.49V10.7655Z"
        fill="#FAFF00"
      />
    </svg>
  );
}

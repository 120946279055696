"use client";

import Link from "next/link";
import { motion } from "framer-motion";

interface NavLinkProps {
  href: string;
  currentPath: string;
  children: React.ReactNode;
}

export function NavLink({ href, currentPath, children }: NavLinkProps) {
  const isActive = currentPath === href;

  return (
    <div className="relative flex items-center justify-center">
      {isActive && (
        <motion.div
          className="absolute pointer-events-none w-full h-full flex items-center justify-center bg-[#413F2C] clipped-10 p-px rounded"
          layoutId="underline"
          initial={false}
          transition={{ duration: 0.3 }}
        >
          <div className="clipped-10 rounded bg-[#1D1B04] w-full h-full" />
        </motion.div>
      )}
      <Link
        className={`lg:text-xs font-mono relative uppercase p-3 px-4 active:scale-95 transition-transform ${
          isActive ? "text-brand font-bold" : "lg:text-white/40 font-medium"
        }`}
        href={href}
      >
        {children}
      </Link>
    </div>
  );
}

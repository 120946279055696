"use client";

import { HoverCard } from "@sushiswap/ui";
import { ChainId } from "sushi/chain";
import { useEffect, useState } from "react";
import { useAccount, useSwitchChain } from "wagmi";

interface NetworkAlertProps {
  targetChainId: ChainId;
}

export function NetworkAlert({ targetChainId }: NetworkAlertProps) {
  const { chain, isConnected } = useAccount();
  const { switchChainAsync } = useSwitchChain();
  const [showNetworkAlert, setShowNetworkAlert] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNetworkAlert(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (!showNetworkAlert || chain?.id === targetChainId || !isConnected) {
    return null;
  }

  return (
    <div className="bg-brand flex items-center justify-center z-50 text-black p-2 w-full font-bold">
      <svg
        width="19"
        height="18"
        viewBox="0 0 19 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mr-1"
      >
        <path
          d="M16.7973 13.4997L10.7973 2.99968C10.6665 2.76883 10.4768 2.57682 10.2475 2.44323C10.0183 2.30964 9.75767 2.23926 9.49232 2.23926C9.22698 2.23926 8.96639 2.30964 8.73713 2.44323C8.50787 2.57682 8.31815 2.76883 8.18733 2.99968L2.18733 13.4997C2.05509 13.7287 1.98575 13.9886 1.98633 14.2531C1.98692 14.5175 2.05741 14.7771 2.19066 15.0055C2.32391 15.234 2.51519 15.4231 2.7451 15.5538C2.97501 15.6845 3.23538 15.7521 3.49983 15.7497H15.4998C15.763 15.7494 16.0215 15.6799 16.2493 15.5481C16.4771 15.4164 16.6662 15.227 16.7977 14.999C16.9292 14.771 16.9984 14.5125 16.9983 14.2493C16.9982 13.9861 16.9289 13.7276 16.7973 13.4997Z"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5 6.75V9.75"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.5 12.7498H9.51"
          stroke="black"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      Wrong network
      <button
        onClick={async () =>
          await switchChainAsync?.({ chainId: targetChainId })
        }
        className="underline ml-4 text-[#212121] hover:opacity-80"
      >
        Switch network
      </button>
    </div>
  );
}

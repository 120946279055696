import { ReactNode } from "react";

export default function NavLinksContainer({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <nav className="bg-white/20 p-px clipped-12 rounded-md">
      <div className="clipped-12 bg-black flex items-center rounded-md p-1 justify-center gap-4 relative ">
        {children}
      </div>
    </nav>
  );
}

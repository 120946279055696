"use client";

import Link from "next/link";
import BlackholeIcon from "./blackhole-icon";
import { usePathname } from "next/navigation";
import { SUPPORTED_CHAIN_IDS } from "src/config";
import { UserProfile } from "src/lib/wagmi/components/user-profile";
import { NavLink } from "./nav-link";
import { NetworkAlert } from "./network-alert";
import NavLinksContainer from "./navlinks-container";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { Sheet, SheetContent, SheetTrigger } from "@sushiswap/ui";

export default function EvmNavbar() {
  const currentPath = usePathname();
  const targetChainId = SUPPORTED_CHAIN_IDS[0];

  return (
    <header className="sticky top-0 z-20 flex flex-col">
      <div className="lg:grid flex gap-4 grid-cols-[1fr,auto,1fr] h-[70px] bg-black items-center border-b border-b-white/20 px-4">
        {/* Mobile Menu */}
        <div className="lg:hidden flex items-center">
          <Sheet>
            <SheetTrigger asChild>
              <button className="p-2 clipped hover:bg-white/10 rounded-lg transition-colors">
                <Bars3Icon className="w-6 h-6 text-white" />
              </button>
            </SheetTrigger>
            <SheetContent
              side="left"
              className="w-[300px] sm:w-[400px] bg-black"
            >
              <nav className="flex flex-col gap-4 mt-16">
                <NavLink href="/swap" currentPath={currentPath}>
                  Swap
                </NavLink>
                <NavLink href="/pool/my-positions" currentPath={currentPath}>
                  Pools
                </NavLink>
                <NavLink href="/quests" currentPath={currentPath}>
                  Quests
                </NavLink>
                <NavLink href="/leaderboard" currentPath={currentPath}>
                  Leaderboard
                </NavLink>
                <NavLink href="/about" currentPath={currentPath}>
                  About
                </NavLink>
              </nav>
            </SheetContent>
          </Sheet>
        </div>
        <div className="flex h-full justify-center lg:justify-start items-cente">
          <Link
            href="/"
            className="flex items-center active:scale-[0.98] transition-transform"
          >
            <BlackholeIcon width={"100%"} className="max-w-[248px]" />
          </Link>
        </div>
        {/* Desktop Navigation */}
        <div className="hidden lg:flex items-center justify-center">
          <NavLinksContainer>
            <NavLink href="/swap" currentPath={currentPath}>
              Swap
            </NavLink>
            <NavLink href="/pool/my-positions" currentPath={currentPath}>
              Pools
            </NavLink>
            <NavLink href="/quests" currentPath={currentPath}>
              Quests
            </NavLink>
            <NavLink href="/leaderboard" currentPath={currentPath}>
              Leaderboard
            </NavLink>
            <NavLink href="/about" currentPath={currentPath}>
              About
            </NavLink>
          </NavLinksContainer>
        </div>

        <div className="flex-1 flex items-center justify-end gap-4">
          <UserProfile networks={SUPPORTED_CHAIN_IDS} />
        </div>
      </div>
      <NetworkAlert targetChainId={targetChainId} />
    </header>
  );
}
